import React from "react";
import * as styles from "./testimonial.module.css";

export default function TestimonialCard({
  name,
  content,
  starRating,
  children,
}) {
  return (
    <div className={styles.root}>
      <svg className={styles.quoteOpen} viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z"
        />
      </svg>
      <svg className={styles.quoteClose} viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z"
        />
      </svg>
      <div className={styles.text}>{children}</div>
      <div className={styles.footer}>
        <h3 className={styles.person}>{name}</h3>
      </div>
    </div>
  );
}
