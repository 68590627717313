import React  from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import TestimonialCard from "../components/TestimonialCard";
import * as baseStyles from "./index.module.css";

export default function Testimonials() {
  return (
    <Layout>
      <Helmet>
        <title>Testimonials | MyEZSolutions</title>
      </Helmet>
      
      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Testimonials</h1>
          <p>Don't believe us? Here's what our customers have said over the time!</p>
        </div>
        
        <div className={baseStyles.testimonialsContainer}>
          <TestimonialCard name="Taylor Madew" theme={'light'}>
            I saw their ad on facebook, and I decided to try them out. Im super
            happy with the results and service so far! Great customer service,
            it was super easy to set everything up. I personally spoke to a
            gentleman named Blake, super helpful and respectful. Thanks for all
            the help guys!
          </TestimonialCard>
          <TestimonialCard name="Samer Itani" theme={'light'}>
            Working with EZ solutions was such a pleasure. They made getting a
            merchant account easy and stress free. I would like to thank the MY
            EZ team for making the process seamless. Definitely would recommend!
          </TestimonialCard>
        </div>
        
        
      </div>
    </Layout>
  );
}